<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>اضافة مقالة جديدة </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CInput
                  invalid
                  description=""
                  label="الاسم العربي"
                  horizontal
                  autocomplete="title"
                  v-model.trim="form.ar.title"
                  invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.ar.title.$anyError"
                />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CInput
                  invalid
                  description=""
                  label="الاسم الانجليزي"
                  horizontal
                  autocomplete="title"
                  v-model.trim="form.en.title"
                  invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.en.title.$anyError"
                />
              </CCol>
            </CRow>

            <CRow class="mt-3 mb-5">
              <CCol sm="12" md="2"> محتوى المقالة العربي </CCol>
              <CCol sm="12" md="9">
                <editor
                  apiKey="mln1amd9gx9droz3rcanvhm9dflaur4dv5zn8udyub03yjph"
                  placeholder="اكتب المحتوى هنا"
                  v-model.trim="form.ar.body"
                  :init="{
                    height: 300,
                    menubar: false,
                    image_uploadtab: true,
                    images_upload_url: `${$tinyUploadImage}`,
                    plugins: [
                      'advlist autolink lists link image charmap',
                      'searchreplace visualblocks code fullscreen',
                      'print preview anchor insertdatetime media',
                      'paste code help wordcount table directionality emoticons',
                    ],
                    toolbar:
                      'emoticons | undo redo image | formatselect | table | link charmap searchreplace visualblocks fullscreen anchor media paste | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | ltr rtl | help',
                  }"
                >
                </editor>
                <h6 v-if="$v.form.ar.body.$anyError" class="text-danger mt-2">
                  يوجد خطأ في هذا الحقل
                </h6>
              </CCol>
            </CRow>

            <CRow class="mt-5">
              <CCol sm="12" md="2"> محتوى المقالة الانجليزي </CCol>
              <CCol sm="12" md="9">
                <editor
                  apiKey="mln1amd9gx9droz3rcanvhm9dflaur4dv5zn8udyub03yjph"
                  placeholder="Write the content here"
                  v-model.trim="form.en.body"
                  :init="{
                    height: 300,
                    menubar: false,
                    image_uploadtab: true,
                    images_upload_url: `${$tinyUploadImage}`,
                    plugins: [
                      'advlist autolink lists link image charmap',
                      'searchreplace visualblocks code fullscreen',
                      'print preview anchor insertdatetime media',
                      'paste code help wordcount table directionality emoticons',
                    ],
                    toolbar:
                      'emoticons | undo redo image | formatselect | bold italic | alignleft aligncenter alignright | table | link charmap searchreplace visualblocks fullscreen anchor media paste | bullist numlist outdent indent | ltr rtl | help',
                  }"
                >
                </editor>
                <h6 v-if="$v.form.en.body.$anyError" class="text-danger mt-2">
                  يوجد خطأ في هذا الحقل
                </h6>
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> الصورة </label>
                  <CCol sm="12" md="9">
                    <VueFileAgent
                      ref="vueFileAgent"
                      :theme="'grid'"
                      :multiple="false"
                      :deletable="true"
                      :meta="true"
                      :accept="'image/*'"
                      :maxSize="'3MB'"
                      :maxFiles="1"
                      :helpText="'اختار صورة او اسحب الى هنا'"
                      :errorText="{
                        type: 'نوع الملف غير صالح . الملفات من نوع صور فقط التي يتم قبولها',
                        size: 'حجم الملف اكبر من ٣ ميجا',
                      }"
                      @select="filesSelected($event)"
                      @beforedelete="onBeforeDelete($event)"
                      @delete="fileDeleted($event)"
                    ></VueFileAgent>
                  </CCol>
                  <CCol sm="12" md="3">
                    <CImg
                      :src="form.image_url"
                      fluid
                      width="150px"
                      height="150px"
                    />
                  </CCol>
                </div>
              </CCol>
            </CRow>

            <CRow class="mt-5">
              <CCol sm="12" md="9">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> حالة المقالة </label>
                  <CCol sm="9">
                    <div role="group" class="form-check form-check-inline">
                      <label>
                        <input
                          type="radio"
                          name="article_id"
                          class="form-check-input"
                          value="1"
                          v-model="form.status"
                        />
                        نشط
                      </label>
                    </div>

                    <div role="group" class="form-check form-check-inline">
                      <label>
                        <input
                          type="radio"
                          name="article_id"
                          class="form-check-input"
                          value="0"
                          v-model="form.status"
                        />
                        متوقف
                      </label>
                    </div>
                  </CCol>
                </div>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
        <CCardFooter>
          <CButton
            :disabled="loading"
            @click="submit"
            type="button"
            color="success"
          >
            <CSpinner v-if="loading" color="white" size="sm" />
            <span v-else> <CIcon name="cil-save" /> حفظ </span>
          </CButton>
        </CCardFooter>
        <CCardFooter v-if="formErrors">
          <CAlert color="danger">
            <CListGroup flush>
              <CListGroupItem v-for="(err, index) in formErrors" :key="index">
                {{ err[0] }}
              </CListGroupItem>
            </CListGroup>
          </CAlert>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import {
  required,
  minLength,
  maxLength,
  url,
  numeric,
} from "vuelidate/lib/validators";
export default {
  name: "Create",
  components: {
    editor: Editor,
  },
  data() {
    return {
      loading: false,
      fileRecords: [],
      fileRecordsForUpload: [],
      formErrors: null,
      ed: "",
      form: {
        ar: {
          title: "",
          body: "",
        },
        en: {
          title: "",
          body: "",
        },
        status: "1",
        image: null,
      },
    };
  },
  validations: {
    form: {
      ar: {
        title: { required, min: minLength(2), max: maxLength(200) },
        body: { required, min: minLength(2) },
      },
      en: {
        title: { min: minLength(2), max: maxLength(200) },
        body: { min: minLength(2) },
      },
      status: { required },
      image: { required },
    },
  },
  created() {},
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return true;
      }
      this.loading = true;

      this.$http
        .post(`/articles`, this.form)
        .then((res) => {
          // handle success
          if (res.data.status == 202) {
            this.$router.push({ name: "Articles" });
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          this.loading = false;
          if ((error.response.data.status == 422)) {
            this.formErrors = error.response.data.error.errors;
          }
        });
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      const reader = new FileReader();
      reader.onloadend = () => {
        this.form.image = reader.result;
      };
      reader.readAsDataURL(validFileRecords[0].file);
    },
    onBeforeDelete: function (fileRecord) {
      this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
    },
    deleteUploadedFile: function (fileRecord) {
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    fileDeleted: function (fileRecord) {
      this.form.image = null;
    },
  },
};
</script>
